import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "styled-components"

import ContentLayout from "../../../components/ContentLayout"
import { Three } from "../../../components/fragments"

const BuildingPage = ({ params: { building, room } }) => {
  const {
    localSearch: { store },
  } = useStaticQuery(
    graphql`
      {
        localSearch: localSearchRooms {
          store
        }
      }
    `
  )
  // TODO: rename nodes in dae to room names to optimise this
  const key = Object.keys(store).find(
    item => store[item].room === room && store[item].building === building
  )

  const data = store[key]

  return (
    <ContentLayout
      title={`${building} Épület: ${data?.name || "Nincs ilyen terem"}`}
      full
      css={css`
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      `}
    >
      <Three building={building} room={data?.nodeId} />
    </ContentLayout>
  )
}

export default BuildingPage
