import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import Layout from "./Layout"
import Container from "./Container"
import CircleImage from "./CircleImage"
import Title from "./Title"
import Hero from "./Hero"

const FullpageContainer = styled(Container)`
  margin-top: 30px;
  padding-top: 10px;
  height: 100%;
`

const ContentLayout = ({ icon, title, children, className, cover, full }) => (
  <Layout>
    <FullpageContainer className={className}>
      {icon && (
        <Link
          to="../"
          css={css`
            position: absolute;
            width: 70px;
            top: 0;
            left: 10px;
            transform: translate(0%, -50%);
            z-index: 15;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-touch-callout: none;
          `}
        >
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <CircleImage
              image={icon}
              css={css`
                margin-bottom: 0;
              `}
              imageSizePercent={60}
            />
          </motion.div>
        </Link>
      )}
      <Title>{title}</Title>
      {cover && <Hero src={cover} />}
      <div
        css={css`
          font-size: 14px;
          color: #707070;
          flex: 1;
          position: relative;
          padding: ${full ? "0" : "0 10px"};
        `}
      >
        {children}
      </div>
    </FullpageContainer>
  </Layout>
)

export default ContentLayout
